import {
    SET_BSVE,
    SAVE_BSVE,
    MODIFY_BSVE, 
    ADD_PROPERTY,
    ADD_PERSON} from './action-types';

export const setBSVE=(payload)=>{
    return {type:SET_BSVE,payload};
}

export const saveBSVE=(payload)=>{
    return {type:SAVE_BSVE,payload};
}

export const addPerson=(payload)=>{
    return {type:ADD_PERSON,payload};
}

export const addProperty=(payload)=>{
    return {type:ADD_PROPERTY,payload};
}

export const modifyBSVE=(payload)=>{
    return {type:MODIFY_BSVE,payload};
}

import React, { Component } from 'react'
import { Container,  Input,Row, Col } from 'reactstrap'
import {FaUser,FaKey,FaBuilding} from 'react-icons/fa';
const KEY_NAME='BalistoUser'
export default class LogIn extends Component {
    constructor(props)
    {
        super(props);
        
        this.state=JSON.parse(localStorage.getItem(KEY_NAME))||{};
        this.handleChange = this.handleChange.bind(this);
        this.dummyLogIn = this.dummyLogIn.bind(this);
        console.log(this);
    }
    dummyLogIn(){
        var u= JSON.stringify(this.state);
        localStorage.setItem(KEY_NAME,u);
        //debugger;
        
        //window.location.reload();
    }

    handleChange(ev) {
        this.setState({ [ev.target.name]: ev.target.value }, () => {
        });
    }

    render() {
        return (
            <Container>
                <Row className="justify-content-sm-center align-self-baseline mt-4">
                    <Col className="col-sm-6">
                        <div className="card card-login align-middle mt-4">
                           
                            <div className="card-body">
                            <div className="mt-4 mb-4 text-center">
                                <h4> Willkommen</h4>
                            </div>
                                <form>
                                <div className="input-group form-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                            <FaBuilding/>
                                            </span>
                                        </div>
                                        <Input type="text" 
                                            name="institut"
                                            value={this.state.institut}  
                                            placeholder="Institut" 
                                            onChange={this.handleChange}
                                            />
                                    </div>

                                    <div className="input-group form-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                            <FaUser/>
                                            </span>
                                        </div>
                                        <Input type="text" 
                                            onChange={this.handleChange}
                                            value={this.state.username}
                                            name="username" 
                                            placeholder="Username" />
                                    </div>

                                    <div className="input-group form-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><FaKey/></span>
                                        </div>
                                        <Input 
                                                type="password" 
                                                name="password" 
                                                placeholder="Password" 
                                                />
                                    </div>

                                    <div className="form-group">
                                        <button onClick={this.dummyLogIn} className="btn btn-outline-info float-right login_btn"> Login </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

import {SET_BSVE,SAVE_BSVE,ADD_PERSON,ADD_PROPERTY} from '../actions/action-types';
import IdbStore from '../stores/bsvestore';

const saveItem=async (payload)=>{
    const db = new IdbStore('kodistore');
    var newObject=Object.assign({}, payload.bsve);
    newObject.cat=new Date();
    var ret=await db.addItem(newObject);

    return ret;
}

const bsve_reducer = (state = {props:{}}, action) => {
    if(action.type === SET_BSVE)
    {
        return Object.assign(state, action.payload);
    }
    else if( action.type === SAVE_BSVE)
    {
        var savedItem=saveItem(action.payload);
        return Object.assign(state,savedItem);
    }
    else if(action.type === ADD_PROPERTY)
    {
        var n= Object.keys(action.payload)[0];
        var newObject=Object.assign({}, state);
        newObject.props[n]=action.payload[n].value;
        return newObject;
    }
    else if(action.type === ADD_PERSON)
    {
        return Object.assign({}, state,{person:action.payload});
    }
    else return state;
}

export default bsve_reducer;
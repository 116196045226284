import React, { Component } from 'react'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import { FaUser, FaCalendar, FaUtensils, FaBell,FaFutbol } from 'react-icons/fa';

export default class MyTimeline extends Component {
    render() {
        return (
            <Timeline>
                 <TimelineEvent title="Training"
                    createdAt="19:00"
                    icon={FaFutbol()}
                >
                    .. die nächste Saison soll besser werden
                </TimelineEvent>
                <TimelineEvent title="Jemanden besuchen"
                    createdAt="14:00"
                    icon={FaUser()}
                    iconColor='#007bff'
                >
                    .. und vieleicht auch mal was verkaufen

                </TimelineEvent>    
                <TimelineEvent title="Mittag"
                    createdAt="12:30"
                    icon={FaUtensils()}
                    iconColor="#17a2b8"
                >
                </TimelineEvent>
                <TimelineEvent title="Frau Meyer anrufen"
                    createdAt="10:06"
                    icon={FaUser()}
                    iconColor='#007bff'
                >
                    Klärung: fehlende Unterlagen

                </TimelineEvent>
                <TimelineEvent
                    title="Frühstück bei Tiffany"
                    createdAt="08:30"
                    icon={FaCalendar()}
                >
                    Brötchen oder Brot? .. kan beides nicht leiden.
                </TimelineEvent>
                <TimelineEvent
                    title="WakeUp"
                    createdAt="07:00"
                    icon={FaBell()}
                    iconColor='grey'
                >
                    Aufstehen .. .
        </TimelineEvent>
            </Timeline>
        )
    }
}

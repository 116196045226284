import React, { Component } from 'react'
import { Fade, Form, FormGroup, Container, Row, Col, Input, Label } from 'reactstrap'
import { bsveService } from './rxjs_service'


export default class Person extends Component {
    constructor(props) {
        super(props);
        this.name = "person";
        this.state = props.data || {};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(ev) {
        this.setState({ [ev.target.name]: ev.target.value }, () => {
            bsveService.addObject({ 'person': this.state });
        });
    }
    render() {
        return (
            <Container className="mt-4">
                <Fade>
                    <div className="text-center border-bottom">Personnendaten</div>
                    <Form>
                        <Row>
                            <Col md="4" className="border-right">


                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Anrede</Label>
                                            <Input type="select" value={this.state.anrede} name="anrede" onChange={this.handleChange}>
                                                <option></option>
                                                <option>Frau</option>
                                                <option>Herr</option>
                                            </Input>
                                        </FormGroup>
                                    </Col><Col>
                                        <FormGroup>
                                            <Label>Titel</Label>
                                            <Input type="select" name="titel" onChange={this.handleChange} value={this.state.titel}>
                                                <option></option>
                                                <option>Dr.</option>
                                                <option>Prof. Dr.</option></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label>Vorname</Label>
                                    <Input type="text" name="vorname" onChange={this.handleChange} value={this.state.vorname}></Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Nachname</Label>
                                    <Input type="text" name="nachname" onChange={this.handleChange} value={this.state.nachname}></Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Geb. Datum</Label>
                                    <Input type="date" name="geb_datum" onChange={this.handleChange} value={this.state.geb_datum}></Input>
                                </FormGroup>


                            </Col>
                            <Col md="4" className="border-right">

                                <FormGroup>
                                    <Label>Strasse</Label>
                                    <Input type="text" value={this.state.strasse} name="strasse" onChange={this.handleChange}></Input>

                                </FormGroup>
                                <FormGroup>
                                    <Label>Hausnummer</Label>
                                    <Input name="hnr" value={this.state.hnr} onChange={this.handleChange} type="text"></Input>

                                </FormGroup>
                                <FormGroup>
                                    <Label>PLZ</Label>
                                    <Input name="plz" value={this.state.plz} onChange={this.handleChange} type="text"></Input>

                                </FormGroup>
                                <FormGroup>
                                    <Label>Ort</Label>
                                    <Input type="text" value={this.state.ort} name="ort" onChange={this.handleChange}></Input>

                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Telefon</Label>
                                    <Input type="text" name="telefon" value={this.state.telefon} onChange={this.handleChange} ></Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Mobile</Label>
                                    <Input type="text" value={this.state.mobile} name="mobile" onChange={this.handleChange}></Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input name="email" value={this.state.email} onChange={this.handleChange} type="email"></Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Fade>
            </Container>
        )
    }
}

import React, { Component } from 'react'
import { Fade, Container, Row, Col, Label, Input, FormGroup } from 'reactstrap'
import MoneySlider from './MoneySlider';
import { spardatenService, bsveService } from './rxjs_service'
import {toFormatedString} from '../../components/common/util';

const tarife = [
  {
    id: 1,
    name: 'Classic Young',
    mind_bwz: 214, //Mindestbewertungszahl 
    guthaberzins_prz: 1.5, // in %
    bwz_factor_zsn: 25, //Zinsfaktor
    min_ans_prz: 40 // Ansparguthaben in %
    
  },
  {
    id: 2,
    name: 'LBS-Classic A5',
    mind_bwz: 214,
    guthaberzins_prz: 0.25,
    bwz_factor_zsn: 20,
    min_ans_prz: 40
  },
  {
    id: 3,
    name: 'Standard',
    mind_bwz: 214,
    guthaberzins_prz: 0.1,
    bwz_factor_zsn: 10,
    min_ans_prz: 40
  }
]

export default class Spardaten extends Component {
  constructor(props) {
    super(props);
    this.name = "spardaten";
    let d= new Date();
    d.setDate(1)
    d.setMonth(d.getMonth() + 1);
    this.state = props.spardaten || { tarif: tarife[0].id, angebot: {},vertragsbegin:d.toISOString().substr(0,10) };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    var self = this;
    this.subscription = spardatenService.getService().subscribe(ev => {
      if (!ev || ev.o.type !== 'up') { return; }
      delete ev.o.type;
      self.setState(ev.o,
        bsveService.addObject({ spardaten: this.state }));
      self.calc(ev);
    });
  }

  handleChange(ev) {
    spardatenService.addSpardatenEntity({ [ev.target.name]: ev.target.value, type: 'up' });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  calc(ev) {
    let self = Object.assign({}, this.state);
    let laufzeit_month = 12 * self.spardauer;
    const tarif = tarife.find(t => { return t.id === parseInt(self.tarif); })
    let zinsfaktor = 1 + (tarif.guthaberzins_prz / 100)/12;
    let source_field = Object.keys(ev.o)[0];
    let it = [];

    if (!tarif || !self.spardauer) return;

    // Bausparsumme    
    if (source_field === 'bausparsumme') { // slider bausparsumme canges
      let sparrate = (self.bausparsumme / laufzeit_month / 2.5) - self.vl;
      if (sparrate > 100 && sparrate <= 400) {
        spardatenService.addSpardatenEntity({ montl_sparrate: sparrate, type: 'down' });

        this.setState({ montl_sparrate: sparrate }); // Atention -> Async fn
        self.montl_sparrate = sparrate;
      }
      else if (sparrate > 300) {
        let dauer = self.spardauer + 1;
        spardatenService.addSpardatenEntity({ spardauer: dauer, type: 'down' });
        this.setState({ spardauer: dauer });
        self.spardauer = dauer;
      }
      else {
        let dauer = self.spardauer - 1;
        spardatenService.addSpardatenEntity({ spardauer: dauer, type: 'down' });
        this.setState({ spardauer: dauer });
        self.spardauer = dauer;
      }
    }
    else { //sparrate oder laufzeit changes
      let bausparsumme = (self.montl_sparrate + self.vl) * laufzeit_month * 2.5;
      spardatenService.addSpardatenEntity({ bausparsumme: bausparsumme, type: 'down' });
      this.setState({ bausparsumme: bausparsumme });
      self.bausparsumme = bausparsumme;
    }

    // Zuteillungstermin Iterration 
    let i = 1;
    laufzeit_month = 12 * self.spardauer;
    while (i < (laufzeit_month + 1)) {
      let zt= new Date(self.vertragsbegin);
      zt.setMonth(zt.getMonth() + i);
      let vormonat = it[it.length - 1] || { monat: 0, guthaben: 0 };
      let sparbetrag = self.montl_sparrate + self.vl;
      let m = { monat: i, bausparsumme: self.bausparsumme ,zt:zt.toLocaleDateString()};
      //m.zinsen = Math.floor(i/12) * zinsfaktor;
      let ansparsumme = parseFloat((vormonat.guthaben + sparbetrag) * zinsfaktor).toFixed(2);
      m.guthaben = parseFloat(ansparsumme);
      m.anspargrad = parseFloat(m.guthaben / self.bausparsumme * 100).toFixed(3);
      let zinsen = m.guthaben - (i * sparbetrag);
      
      m.zinsen = zinsen;
      
      m.bewertungszahl = parseFloat((m.guthaben + (zinsen * tarif.bwz_factor_zsn)) / (self.bausparsumme * 0.004)).toFixed(3);
      it.push(m);
     
      
      if (parseFloat(m.anspargrad) > tarif.min_ans_prz &&  m.bewertungszahl > tarif.mind_bwz) {
       // this.setState({ angebot: m });
        break;
      }

      i++;
    }
    this.setState({angebot:it[it.length -1]});
    console.table(it);
  }

  render() {
    const tarife_opt = tarife.map(t => <option key={t.id} value={t.id}>{t.name}</option>);
    return (
      <Container className="mt-4">
        <Fade>
          <Row>
            <Col md="7" className="border-right">
              <div className="text-center">Spardaten</div>
              <MoneySlider
                label="Monatliche Sparrate"
                name="montl_sparrate"
                append="€"
                min="0"
                max="1000"
                step="10"
                init-value={100}
              />
              <MoneySlider
                label="Vermögenwirksame Leistungen"
                name="vl"
                append="€"
                min="0"
                max="40"
                step=".5"
                init-value="0"
              />
              <MoneySlider
                label="Spardauer"
                name="spardauer"
                append="Jahre"
                min="5"
                max="20"
                step="1"
                init-value="7"
              />

              <MoneySlider
                label="Bausparsumme"
                name="bausparsumme"
                append="€"
                min="10000"
                max="300000"
                step="1000"
                init-value={70000}
              />
            </Col>
            <Col className="mb4">
                <div className="text-center">Angebot</div>
                <FormGroup>
                <Label>Tarif</Label>
                <Input type="select" name="tarif" onChange={this.handleChange} value={this.state.tarif}>
                  {tarife_opt}
                </Input>
              </FormGroup>
              <FormGroup>
              <Label>Vertagsbegin</Label>
               <Input type="date" name="vertragsbegin" value={this.state.vertragsbegin} onChange={this.handleChange}></Input> 
               </FormGroup>
              <Row className="mb-4">
                <Col>Zuteilungstermin</Col>
                <Col className="text-right border-bottom">{this.state.angebot.zt}</Col>
              </Row>
              <Row className="mb-4">
                <Col>Guthaben</Col>
                <Col className="text-right border-bottom">{toFormatedString(this.state.angebot.guthaben)}</Col>
              </Row>
              <Row className="mb-4">
                <Col>Anspargrad</Col>
                <Col className="text-right border-bottom">{this.state.angebot.anspargrad} %</Col>
              </Row>
              <Row className="mb-4">
                <Col>Bewertungszahl</Col>
                <Col className="text-right border-bottom">{this.state.angebot.bewertungszahl}</Col>
              </Row>
            </Col>
          </Row>

        </Fade>

      </Container>
    )
  }
}

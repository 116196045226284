export const Branchen=['Administration / Verwaltung / Bürowesen',
'Automobil / Automobilzulieferer',
'Banken',
'Baugewerbe / Architektur',
'Beratung / Consulting',
'Bildung / Universität / FH / Schulen',
'Chemie',
'Dienstleistung',
'Druck / Papier / Verpackung',
'EDV / IT',
'Einkauf / Beschaffung',
'Elektro / Elektronik',
'Energiewirtschaft',
'Finanzen',
'Forschung / Entwicklung / Wissenschaft',
'Gesundheitswesen / Soziales / Pflege',
'Handel / Konsum',
'Handwerk',
'Immobilien / Facility Management',
'Industrie',
'Internet / Multimedia',
'Kunst / Kultur / Unterhaltung',
'Marketing / Werbung / PR',
'Marktforschung',
'Maschinen / Anlagenbau',
'Medien',
'Medizin / Pharma',
'Medizintechnik',
'Nahrungsmittel / Land / Forstwirtschaft',
'Personalwesen / Personalbeschaffung',
'Rechtsberatung',
'Seminar / Messeanbieter',
'Sonstige Branchen',
'Sport / Fitness / Beauty',
'Steuerberatung / Wirtschaftsprüfung',
'Telekommunikation',
'Textilbranche',
'Tourismus / Hotel / Gastronomie',
'Vereine',
'Verkehr / Transport / Logistik',
'Versicherung',
'Öffentliche Verwaltung']
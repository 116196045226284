import React, { Component } from 'react'
import { Fade, Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import Vorgangsliste from './Vorgangsliste';
import MyTimeline from './MyTimeline'

export default class Dashboard extends Component {
  render() {
    return (
      <Container className="mt-4">
        <Fade>
          <Row>
            <Col md="6 p-4">
              <Card>
                <CardBody style={{ height: '70vh', overflow: 'auto' }}>
                  <h5 className="border-bottom text-center">Kunden &amp; Termine</h5>
                  <MyTimeline></MyTimeline>
                </CardBody>
              </Card>
            </Col>
            <Col md="6 p-4">
              <Card>
                <CardBody style={{ height: '70vh', overflow: 'auto' }}>
                <h5 className="border-bottom text-center">Meine Vorgänge</h5>
                  <Vorgangsliste></Vorgangsliste>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Fade>
      </Container>
    )
  }
}

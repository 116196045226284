import React, { Component } from 'react'
import { FaFingerprint, FaMoneyCheck, FaUserFriends } from 'react-icons/fa';
import { bsveService } from './rxjs_service';

import IdbStore from '../../stores/bsvestore'
import Spardaten from './Spardaten';
import Person from './Person';
import Legitimation from './Legitimation';
import Lastschrift from './Lastschrift';
import BottomNav from './BottomNav';

export default class Bsv extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    saveState() {

    }
    componentWillMount() {
        var self = this;
        this.subscription = bsveService.getService().subscribe(ev => {
            if (!ev || !self) return;
            self.setState(ev,()=>{
                
            });
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
        const db = new IdbStore('bsves');
        db.addItem(this.state);
    }

    render() {
        const conf =
        {
            '#Spardaten': <Spardaten data={this.state.spardaten}/>,
            '#Person': <Person data={this.state.person}/>,
            '#Legitimation':<Legitimation data={this.state.legitimation}/>,
            '#Lastschrift': <Lastschrift data={this.state.lastschrift}/>,
            '#Beguenstigung': <FaUserFriends className="m-4" />
        }
        const keys = Object.keys(conf);
        const state = this.props.location.hash || keys[0];
        const view = conf[state];
        return (
            <div>
                {view}
                <BottomNav active={state}></BottomNav>
            </div>
        )
    }
}

import React, { Component } from 'react'
import { Fade, Form, FormGroup, Container, Row, Col, Input, Label } from 'reactstrap'
import { bsveService } from './rxjs_service'
import { Branchen } from './constants'

export default class Legitimation extends Component {
    constructor(props) {
        super(props);
        this.state = props.data || {};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(ev) {
        this.setState({ [ev.target.name]: ev.target.value }, () => {
            bsveService.addObject({ 'legitimation': this.state });
        });
    }

    render() {
        const branchen_options = Branchen.map((b) =>
            <option key={b}>{b}</option>
        );

        return (
            <Container className="mt-4">
                <Fade>
                    <Form>
                        <Row>
                            <Col lg="6" className="border-right">
                                <div className="text-center border-bottom">Legitimation</div>
                                <FormGroup>
                                    <Label>Dokumentenart</Label>
                                    <Input type="text"onChange={this.handleChange}  value={this.state.dokumentenart} name="dokumentenart" placeholder="Dokumentenart" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Dokumenten-Nr.</Label>
                                    <Input type="text" onChange={this.handleChange} value={this.state.dokumentennummer} name="dokumentennummer" placeholder="Dokumenten Nummer" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ausstellende Behörde</Label>
                                    <Input type="text" onChange={this.handleChange} value={this.state.austeller} name="austeller" placeholder="Ausstellende Behörde" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ausstellungsdatum</Label>
                                    <Input type="date" onChange={this.handleChange} value={this.state.austellungsdatum} name="austellungsdatum" placeholder="Ausstellungsdatum" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ablaufdatum</Label>
                                    <Input type="date" onChange={this.handleChange} value={this.state.ablaufdatum} name="ablaufdatum" placeholder="Ablaufdatum" />
                                </FormGroup>
                            </Col>
                            <Col>
                                <div className="text-center border-bottom">Berufliche Angaben</div>
                                <FormGroup>
                                    <Label>Berufsgruppe</Label>
                                    <Input type="select" onChange={this.handleChange} value={this.state.berufsgruppe} name="berufsgruppe">
                                        <option>Angestellter</option>
                                        <option>Schüler/ Studenten</option>
                                        <option>Selbstständige/ Freiberufler</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Beruf</Label>
                                    <Input type="text" onChange={this.handleChange} value={this.state.beruf} name="beruf" placeholder="Berufsbezeichnung" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Arbeitgeber</Label>
                                    <Input type="text" onChange={this.handleChange} value={this.state.arbeitgeber} name="arbeitgeber" placeholder="Arbeitgeber" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Branche</Label>
                                    <Input type="select" onChange={this.handleChange} value={this.state.branche} name="branche">
                                        <option> -- bitte wählen -- </option>
                                        {branchen_options}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Beschäftigt seit</Label>
                                    <Input type="date" onChange={this.handleChange} value={this.state.beruf_start} name="beruf_start" placeholder="Beschäftigt seit" />
                                </FormGroup>
                                
                                <FormGroup className="border-top pt-2 mt-2">
                                    <Label>Steuer-ID</Label>
                                    <Input type="text" onChange={this.handleChange} value={this.state.steuer_id} name="steuer_id" placeholder="Steuer ID" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Fade>
            </Container>
        )
    }
}

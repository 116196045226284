import {BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

const subject = new BehaviorSubject();
const spardatenSubject = new BehaviorSubject();

export const bsveService = {
    addObject: (object) => subject.next(object),
    getService: () => subject.pipe(delay(100)).asObservable(),
    getValue: () => subject.getValue()
};

export const spardatenService = {
    addSpardatenEntity:(o) => spardatenSubject.next({o}),
    getService: () => spardatenSubject.pipe(delay(100)).asObservable(),
}
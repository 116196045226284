import React, { Component } from 'react';
import { Label, Row, Col, InputGroup, InputGroupAddon, FormGroup, Input } from 'reactstrap'
import { spardatenService } from './rxjs_service';
import Slider from 'rc-slider';
import {toFormatedString} from '../../components/common/util';
export default class MoneySlider extends Component {
  constructor(props) {
    super(props);
    this.name = props.name;
    this.digits = parseInt(props.digits) || 2;
    var val = props['init-value'] || 0;
    this.state = this.getValue(val);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormat = this.handleFormat.bind(this);
    this.slideHandler = this.slideHandler.bind(this);
   }

  notify(){
    spardatenService.addSpardatenEntity({ [this.name]: this.state.value ,type:'up'});
  }
  getValue(val) {
    var fv = parseFloat(val).toFixed(this.digits).replace(/\./, ',');
    return { value: parseFloat(val), formatedValue: fv };
  }

  componentWillMount() {
    let self=this;
    this.subscription = spardatenService.getService().subscribe(ev => {
      if (!ev || ev.o.type!=='down') return;
      if(Object.keys(ev.o)[0]===this.name){
        let val= self.getValue(ev.o[self.name]);
        self.setState(val); 
        };
    });
    this.notify();
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleChange(ev) {
    var rval = ev.target.value.replace(/,/, '.');
    var x = parseFloat(rval);
    this.setState(this.getValue(x));
  }

  handleFormat(ev) {
    let self=this;
    let val = ev.target.value.replace(/,/, '.');
    let x = parseFloat(val);

    this.setState(this.getValue(x), () => {
      self.notify();
    })
  }

  slideHandler(ev) {
    let self=this;
      
    this.setState(this.getValue(ev), () => {
    self.notify();
    });
  }
/*
            <InputGroup>
              <Input value={this.state.formatedValue}
                onChange={this.handleChange}
                onBlur={this.handleFormat}
                name={this.props.name}
                type="text"
                placeholder=""
                style={{ 'textAlign': 'right' }}
              ></Input>
              <InputGroupAddon addonType="append">{this.props.append}</InputGroupAddon>
            </InputGroup>
*/
  render() {
   let val= toFormatedString(this.state.value);

    if(this.props.append==='Jahre'){
      val=this.state.value+' '+this.props.append;
    }
    return (
      <FormGroup className="mt-5">
        <Row>
          <Col className="col-sm-7">
            <Label>{this.props.label}</Label>
          </Col>
          <Col className="text-right">
<Label>{val}</Label>
          </Col>
        </Row>

        <Slider
          min={parseFloat(this.props.min) || 0}
          max={parseFloat(this.props.max) || 1000}
          step={parseFloat(this.props.step) || .01}
          value={this.state.value}
          onChange={this.slideHandler}
        ></Slider>
      </FormGroup>
    )
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(MoneySlider)
import React, { Component } from 'react'
import { FaSlidersH, FaUser, FaFingerprint, FaMoneyCheck, FaUserFriends } from 'react-icons/fa';
import {
  Navbar,
  Nav,
  NavItem,
  NavLink, 
} from 'reactstrap';

export default class BottomNav extends Component {

  constructor(props) {
    super(props);
    this.togle = this.togle.bind(this);
    this.state = { isOpen: false }
  }

  togle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  clickHanler(ev){
    console.log(ev);
  }
  render() {
    return (
      <div>
        <Navbar className="fixed-bottom  mt-4 justify-content-sm-center" color="light">
          <Nav>
            <NavItem>
              <NavLink href="#Spardaten" className="text-inline active" >
                <span className="text-succsess"><FaSlidersH> </FaSlidersH> Spardaten</span>
              </NavLink>
            </NavItem>
            <NavItem>

              <NavLink href="#Person" className="text-inline">
                <FaUser></FaUser> <span> Person</span>
              </NavLink>
            </NavItem>
            <NavItem>

              <NavLink href="#Legitimation" className="text-inline">
                <FaFingerprint /><span> Legitimation</span>
              </NavLink>
            </NavItem>
            <NavItem>

              <NavLink href="#Lastschrift" className="text-inline">
                <FaMoneyCheck /><span> Lastschrift</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="#Beguenstigung" className="text-inline">
                <FaUserFriends /><span> Begünstigung</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>

      </div >
    )
  }
}


/*
 <Navbar expand="md" className="fixed-bottom" color="secondary">
        <NavbarToggler onClick={this.togle} className="mr-2">
          <FaBars style={{ color: 'fff' }}></FaBars>
        </NavbarToggler>
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto text-center" navbar>

            <NavItem>
              <NavLink href="#">
                <FaUser></FaUser> Person
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/start">Legitimation                                                                 </NavLink>
            </NavItem>

          </Nav>
        </Collapse>
      </Navbar>

*/
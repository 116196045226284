import React, { Component } from 'react';
import { FaBars,FaReact } from 'react-icons/fa';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
class TopNav extends Component {

    constructor(props) {
        super(props);
        this.togle = this.togle.bind(this);
        this.state = { isOpen: false }
    }

    togle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div>
                <Navbar expand="md" className="fixed-top" color="info">
                    <NavbarBrand href="/" className="text-inline">
                       <FaReact/> iBaufi                   
                    </NavbarBrand>
                    <NavbarToggler onClick={this.togle} className="mr-2">
                        <FaBars style={{ color: 'fff' }}></FaBars>
                    </NavbarToggler>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar>

                                <NavItem>
                                    <NavLink href="/">Home                                                                 </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="/start">Start                                                                 </NavLink>
                                </NavItem>

                            </Nav>
                        </Collapse>

                    </Collapse>

                </Navbar>
            </div>
        )
    }
}
export default TopNav;
import React, { Component } from 'react'
import { Fade, Form, FormGroup, Container, Row, Col, Input, Label } from 'reactstrap'
import { bsveService } from './rxjs_service'

export default class Lastschrift extends Component {

    constructor(props) {
        super(props);
        this.state = props.data || {};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(ev) {
        this.setState({ [ev.target.name]: ev.target.value }, () => {
            bsveService.addObject({ 'lastschrift': this.state });
        });
    }

    render() {
        return (
            <Container className="mt-4">
                <Fade>
                    <div className="text-center border-bottom">Lastschrifteinzug</div>
                    <Form>
                        <Row>
                            <Col lg="7" className="border-right">

                                <FormGroup>
                                    <Label>Länder-KZ</Label>
                                    <Input type="select" value={this.state.countrycode} name="anrede" onChange={this.handleChange}>
                                        <option>DE</option>
                                        <option>NL</option>
                                        <option>AT</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>IBAN</Label>
                                    <Input type="text" name="IBAN" onChange={this.handleChange} value={this.state.IBAN}></Input>
                                </FormGroup>
                                <FormGroup>
                                    <Row form>
                                        <Col md={6}>
                                            <Label>BIC</Label>
                                            <Input type="text" name="BIC" onChange={this.handleChange} value={this.state.IBAN}></Input>
                                        </Col>
                                        <Col>
                                            <Label>Institut</Label>
                                            <Input type="text" name="institut" onChange={this.handleChange} value={this.state.institut}></Input>
                                        </Col>
                                    </Row>
                                </FormGroup>

                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Sparrate</Label>
                                    <Input type="text" name="sparrate" onChange={this.handleChange} value={this.state.sparrate} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Rhytmus</Label>
                                    <Input type="select" name="rhytmus" onChange={this.handleChange} value={this.state.rhytmus}>
                                        <option>Monatlich</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Einzugstag</Label>
                                    <Input type="select" name="einzugstag" onChange={this.handleChange} value={this.state.einzugstag}>
                                        <option>01</option>
                                        <option>15</option>
                                        <option>30</option>

                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Begin</Label>
                                    <Input type="date" name="begin" onChange={this.handleChange} value={this.state.begin} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ende</Label>
                                    <Input type="date" name="ende" onChange={this.handleChange} value={this.state.ende} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Fade>
            </Container>
        )
    }
}

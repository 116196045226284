import React, { Component } from 'react'
import './App.css'
import TopNav from './components/topnav/topnav'
import LogIn from './components/common/LogIn'
import Start from './components/common/Start'
import Dashboard from './components/common/Dashboard'
import Bsv from './components/bsve';

import { BrowserRouter as Router, Route,Switch } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <div className="App">
        <TopNav></TopNav>
          <div className="pt-5"></div>
          <Router>
          <Switch>
            <Route path="/" exact component={Dashboard}></Route>
            <Route path="/bsve" exact component={Bsv}></Route>
            <Route path="/start" component={Start}></Route>
            <Route path="/login" component={LogIn}></Route>
            <Route path="/bsv/:id" component={Bsv}></Route>
            </Switch>
          </Router>
      </div>
    );
  }
}

export default App;

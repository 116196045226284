import React, { Component } from 'react'
import { Link } from "react-router-dom";


class KachelNav extends Component {

    constructor(props) {
        super(props);
        this.listen_mouseover = true;
        this.state = { mouseover: true };
        this.goto = this.goto.bind(this);
        this.clickCount=0;
    }

    goto(ev) {
        // dirty , i know .. but it works :-)
        if(this.clickCount === 0)
        {ev.preventDefault(); this.clickCount++;}
    }
    render() {
        return (
            <Link to={this.props.nav.href||'#'} onClick={this.goto} className="col p-2 KachelNav">
                <div className="Title">{this.props.nav.title}</div>
            </Link>
        )
    }
}

export default class Kachel extends Component {
    constructor(props) {
        super(props)
        this.state = {active:false};
        this.classNames = { Kachel: true }
        this.setActive = this.setActive.bind(this);
        this.setInActive = this.setInActive.bind(this);
        this.active=false;
    }

    setActive(ev) {
        const activeClassName = 'active'
        var classList = ev.currentTarget.classList;
        classList.add(activeClassName);
        this.setState({active:true})
    }

    setInActive = (ev) => {
        const activeClassName = 'active'
        var classList = ev.currentTarget.classList;
        classList.remove(activeClassName);
    }

    listNav() {
        if (!this.props.nav) { return (<div></div>); }
        const fn = this.props.nav.map((i) =>
            <KachelNav key={i.title} nav={i}></KachelNav>
        );
         return (<div className="d-flex flex-row justify-content-center NavGrid">{fn}</div>);
    }


    render() {
        return (
            <div className={this.props.className + ' Kachel'}
                onMouseOver={this.setActive}
                onMouseOut={this.setInActive}
                onTouchStart={this.setActive}

            >
                <div className="Title">{this.props.title}</div>
                {this.listNav()}
            </div>
        )
    }
}

import React, { Component } from 'react'
import IdbStore from '../../stores/bsvestore'
import { ListGroupItem, ListGroup, InputGroupAddon, InputGroup, Input, Container, Button } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import {connect} from 'react-redux';
import {setBSVE} from '../../actions';

const mapDispatchToProps=(dispatch)=>{
    return {
        setBSVE: data => dispatch(setBSVE(data))
    };
  }

class Vorgangsliste extends Component {
    constructor(props) {
        super(props);
        this.state = {data: [] };
        this.search = this.search.bind(this);
        this.db = new IdbStore('kodistore');
        //this.loadBsvE=this.loadBsvE.bind(this);
    }
    componentWillMount() {
        this.db.getAll().then(data => {
            this.setState({ data: data });
        })
    }

    search() {
    }
    render() {

        const Items=(props)=> {
            const data = props.data;
            const listItems = data.map((i) =>
                <ListGroupItem  
                tag="a" href={'/bsve/show/' + i._id}
                key={i._id}>{i.person.vorname} {i.person.nachname}</ListGroupItem>
            );
            return (<ListGroup>{listItems}</ListGroup>);
        }

        return (
            <Container fluid={true}> 
                <InputGroup className="mb-4">
                    <Input type="search" placeholder="suche nach ..." />
                    <InputGroupAddon addonType="append">
                        <Button color="info" onClick={this.search}>
                            <FaSearch />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
                <Items data={this.state.data}></Items>
            </Container>
        )
    }
}

export default connect(null,mapDispatchToProps)(Vorgangsliste);
const _DB_NAME = 'bsvedb';
function generateId() {
    return Math.random().toString(36).substring(2)
        + (new Date()).getTime().toString(36);
}
class IdbStore {
    constructor(collectionName) {
        this.collectionName = collectionName
    }

    getAll = () => {
        return new Promise((resolve, reject) => {
            this.getStore().then(({ t, store }) => {
                var r = store.getAll();
                r.onsuccess = (ev) => {
                    resolve(ev.target.result);
                }
                r.onerror = (err) => {
                    reject(err);
                }
            });
        });
    }

    getStore = async () => {
        if (!this.db) {
            try {
                this.db = await this._initDB({ dbname: _DB_NAME, collectionName: this.collectionName });
            }
            catch (err) {
                console.err('hoppallaaa')
            }
        }
        const db = this.db;
        return new Promise((resolve, reject) => {
            var t = db.transaction(this.collectionName, 'readwrite');
            var store = t.objectStore(this.collectionName);
            store.onerror = (err) => {
                reject(err);
            }
            t.onerror = (err) => {
                reject(err);
            }
            resolve({ t, store });
        });
    }

    getItemById = async (_id) => {
        const { t, store } = await this.getStore();
        return new Promise((resolve, reject) => {
            var x = store.get(_id);
            x.onsuccess = (ev) => {
                resolve(ev.target.result);
            };
            x.onerror = (err) => { reject(err); }
        });


    }

    addItem = async (item) => {
        const { t, store } = await this.getStore();
        return new Promise((resolve, reject) => {

            t.onerror = (err) => {
                reject(err);
            }
            t.oncomplete = (ev) => {
                resolve(item);
            }

            if (!item._id) {
                item._id = generateId();
                store.add(item);
            }
            else {
                store.put(item);
            }
        });
    }

    _initDB = async (param) => {

        let p = new Promise((resolve, reject) => {
            var idbor = indexedDB.open(param.dbname, 1);
            idbor.onerror = (err) => { reject(err) };
            idbor.onsuccess = (ok) => {
                resolve(idbor.result);
            };
            idbor.onupgradeneeded = (ev) => {
                var db = idbor.result;
                if (!db.objectStoreNames.contains(param.collectionName)) {
                    var store = db.createObjectStore(param.collectionName, { keyPath: '_id' });
                    store.createIndex('_id', '_id', { unique: true });
                    console.info('%c Collection "%s" created', 'color:blue', param.collectionName);
                }
                resolve(idbor.result);
            }
        })

        return p
    }
}
export default IdbStore;